import React from 'react';
import moment from 'moment-timezone';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import { green } from '@material-ui/core/colors';

import { useGetMarketDataCheckQuery } from '../../services/ReportingService';
import { MarketDataEntry } from "../../services/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    }
  }),
);

function DataAccordion(metric: string, data: MarketDataEntry, classes: any){
  const data_header = (
    <Typography className={classes.heading}>
      {data.ok ? <CheckCircleIcon style={{ color: green[500] }}/> : <CancelIcon color="secondary"/>} {metric} ({data.as_of_dt})
    </Typography>
  );

  let data_entry_rows = []
  for (let [key, value] of Object.entries(data)) {
    if (Array.isArray(value)){
      const value_elements = value.map((el) => <li>{el}</li>)
      value = <ul>{value_elements}</ul>
    }
    if (key === "overall_percent_missing"){
      value = `${value.toFixed(1)}%`
    }
    if (key === "target_last_missing_minute" || key === "last_minute"){
      value = moment(value).tz('America/New_York').format("h:mm z")
    }
    data_entry_rows.push(
      <TableRow key={key}>
        <TableCell component="th" scope="row">{key}</TableCell>
        <TableCell align="right">{value}</TableCell>
      </TableRow>
    );
  }
  const data_text = (
    <TableContainer>
      <Table size="small" aria-label="simple table">
        <TableBody>
          {data_entry_rows}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Accordion key={metric}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id={`panel1a-header-${metric}`}
      >
        {data_header}
      </AccordionSummary>
      <AccordionDetails>
        {data_text}
      </AccordionDetails>
    </Accordion>
  );

}

export default function MarketData(){

  const { data, error, isLoading } = useGetMarketDataCheckQuery()
  const classes = useStyles();

  let content = <p>No request sent to server</p>;

  if (isLoading){
    content = <CircularProgress />
  } else if (error){
    console.log(error)
    content = (
      <div>
        <h3>Error getting market data report</h3>
        <p>{JSON.stringify(error)}</p>
      </div>
    );
  } else if (data) {
    const {daily, minute, trade} = data

    const daily_els: Array<JSX.Element> = [];
    const minute_els: Array<JSX.Element> = [];
    const trade_els: Array<JSX.Element> = [];

    for (let [key, value] of Object.entries(daily)) {
      daily_els.push(DataAccordion(key, value, classes))
    }

    for (let [key, value] of Object.entries(minute)) {
      minute_els.push(DataAccordion(key, value, classes))
    }

    for (let [key, value] of Object.entries(trade)) {
      trade_els.push(DataAccordion(key, value, classes))
    }


    content = (
      <Paper className={classes.paper}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <h3>Daily Report</h3>
            {daily_els}
          </Grid>
          <Grid item xs={4}>
            <h3>Minute Report</h3>
            {minute_els}
          </Grid>
          <Grid item xs={4}>
            <h3>Trade Report</h3>
            {trade_els}
          </Grid>
        </Grid>
      </Paper>
    );
  }
  return (
    <div>
      <h2 style={{ "textAlign": "center" }}>Market Data Report</h2>
      {content}
    </div>
  );
}